#root>div>div>div>main>div>div>div:nth-child(2)>div>div>div.ant-steps-item.ant-steps-item-process.ant-steps-item-active>div>div.ant-steps-item-icon {
    background: green;
    border-color: green;
}

#root>div>div>div>main>div>div>div:nth-child(2)>div>div>div.ant-steps-item.ant-steps-item-finish>div>div.ant-steps-item-icon {
    background: rgb(186, 207, 186);
    border-color: rgb(186, 207, 186);
}

#root>div>div>div>main>div>div>div:nth-child(2)>div>div>div.ant-steps-item.ant-steps-item-finish>div>div.ant-steps-item-icon svg {
    fill: green;
}